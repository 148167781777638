import * as React from 'react';

export default function LogoIcon(props: { className?: string }) {
  return (
    <svg width="100" height="40" xmlns="http://www.w3.org/2000/svg" className={props.className}>
      <path
        id="svg_2"
        d="m93.66,20.162c1.152,-7.839 -4.795,-15.18 -10.201,-14.454c-1.297,0.169 -2.323,0.479 -3.141,0.866c-3.344,1.587 -3.111,4.457 -3.111,4.457c3.334,-2.879 9.388,-1.06 8.778,4.07c-0.523,4.418 -10.026,4.273 -18.073,-0.358c1.636,-0.227 2.424,-1.239 2.424,-1.239c-2.628,0.76 -4.544,-2.371 -7.675,-3.736c-4.2,-1.829 -7.254,-1.461 -7.675,-1.398l-0.024,0c-3.136,0.102 -4.965,1.703 -4.965,1.703s-1.824,-1.602 -4.955,-1.703c0,0 -0.005,0 -0.01,0c-0.315,-0.048 -3.411,-0.465 -7.694,1.398c-3.131,1.365 -5.047,4.495 -7.675,3.736c0,0 0.789,1.011 2.424,1.239c-8.047,4.631 -17.551,4.776 -18.074,0.358c-0.61,-5.129 5.444,-6.949 8.778,-4.07c0,0 0.232,-2.87 -3.111,-4.457c-0.818,-0.387 -1.844,-0.697 -3.141,-0.866c-5.403,-0.726 -11.35,6.615 -10.199,14.454c-0.972,-1.031 -1.34,-2.211 -1.34,-2.211c0.557,6.107 4.65,9.209 4.65,9.209c3.097,3.15 8.744,5.182 15.378,5.182c3.842,0 8.013,-0.682 12.214,-2.226c1.645,-0.605 3.082,-1.268 4.331,-1.969c-1.969,2.11 -4.21,3.484 -4.21,3.484c9.189,-2.042 11.851,-11.28 11.851,-11.28c0.392,-0.919 0.59,-1.694 0.687,-2.24c0.097,-0.552 0.097,-0.866 0.097,-0.866s0,0.315 0.102,0.866c0.097,0.547 0.295,1.321 0.687,2.24c0,0 2.661,9.238 11.851,11.28c0,0 -2.24,-1.374 -4.21,-3.484c1.248,0.702 2.686,1.365 4.331,1.969c4.2,1.544 8.371,2.226 12.214,2.226c6.634,0 12.281,-2.032 15.378,-5.182c0,0 4.094,-3.102 4.65,-9.209c-0.001,0 -0.369,1.18 -1.341,2.211z"
      />
    </svg>
  );
}
